// import service worker script
importScripts('https://storage.googleapis.com/workbox-cdn/releases/4.2.0/workbox-sw.js');

workbox.core.skipWaiting();
//workbox.core.clientsClaim();

const backgroundSync = new workbox.backgroundSync.Plugin('coucou_guide_bs');
const expiration  = new workbox.expiration.Plugin({
        // Only cache requests for a 30 day
        maxAgeSeconds: 30 * 7 * 24 * 60 * 60
    });
const broadcastUpdate = new workbox.broadcastUpdate.Plugin({
        channelName: 'coucou_guide_broadcast',
    });

// Network First
[ 
    '/$',  // Index 
    '/*'   // Anything in the same host
    //'.+/*' // Anything in any host
]
.forEach(mask => {
    workbox.routing.registerRoute(
        new RegExp(mask),
        // CacheFirst or NetworkFirst
        new workbox.strategies.CacheFirst({
            cacheName: 'coucou_guide_cache',
            plugins: [backgroundSync, expiration, broadcastUpdate]
        })
    );
});



function nukeCache(cacheName) {
    return caches.delete(cacheName).then(removed => {
        return removed;
    });
}

self.onmessage = function(e) {
    const action = e.data.action;
    const cacheName = e.data.cacheName;

    if (action === 'clearcache') {
        nukeCache(cacheName).then(removed => {
            // ok
        });
    }
};